<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
         Real Estate Licensees
        </strong>
        <!-- <hwa-button
          v-if="$can('create-review', 'hwa')"
          icon="PlusIcon"
          label="Add Review"
          @onClick="openReviewModal()"
        /> -->
      </div>
      <b-table
        small
        selectable
        select-mode="single"
        :fields="fields"
        :items="associated_licensees"
        responsive="sm"
      >
        <template #cell(full_name)="data">
          {{ data.value }}
        </template>
        <template #cell(email)="data">
          {{ data.value }}
        </template>
        <template #cell(phone)="data">
          {{ data.value }}
        </template>
        <template #cell(action)="data">
          <b-form-checkbox
            v-b-tooltip.hover.right="data.item.selected ? `deselect licensee` : `select licensee`"
            class="custom-control-success"
            name="check-button"
            switch
            :checked="data.item.selected ? true : false"
            @change="toggleLicenseeSelection(data.item)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="UserCheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="UserXIcon" />
            </span>
          </b-form-checkbox>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard, BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import realestateLicensee from '@/mixins/realestateLicensee'

export default {
  components: {
    BTable,
    BCard,
    BFormCheckbox,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [{key:'full_name',label:"Full Name",sortable:true}, 'email', 'phone'],
      reviewLoading: false,
      updateReviewMode: false,
      reviewModalOpened: false,
    }
  },
  mixins: [showToast, realestateLicensee],
  mounted() {
    this.getAssociatedLicensees()
  },
  methods: {
  },
}
</script>
